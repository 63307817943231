<template>
    <div style="max-width: 400px;margin: 0 auto;">
        <div>
            <b style="color: #4D4D4D;">需要被转移的类目：</b>
            <span style="color: #808080;">{{cat.name}}</span>
        </div>
        <div style="padding:15px 0;color: #4D4D4D;"><b>请选择转移到哪个类目下</b></div>
        <div class="tree">
            <el-tree :data="list" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </div>
        <div style="padding-top:20px;" class="flex flex-justify-around">
            <el-button style="width:80px" type="primary" size="small" @click="hide()">取消</el-button>
            <el-button style="width:80px" type="primary" size="small" @click="submit()">确定</el-button>
        </div>

    </div>
</template>
<script>
    import { fetchTree } from '@/util';
    import { IMG_URL_PRE } from '@/config';
    import { catList, catMove } from '@/service/dictionary';
    export default {
        props: ['cat','popTitle'],
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                list: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                ruleFrom:{},
            }
        },
        watch:{
            cat:{
                handler() {
                    this.refreshItems();
                },
                deep: true
            }
        },
        created() {
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems'); 
                catList().then(rst => {
                    if (rst && rst.length>0) {
                        // this.list = fetchTree(Object.values(rst), null);
                        this.list = fetchTree(rst, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
                    }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            handleNodeClick(item) {
                // console.log(item);
                this.ruleFrom = {
                    id: this.cat.id,
                    newParentId:item.id
                }
            },
            hide() {
                this.$emit("hide");
            },
            submit() {
                if (this.ruleFrom && !this.ruleFrom.newParentId) {
                    return this.$message.error('请选择转移到哪个类目下');
                }
                catMove(this.ruleFrom).then(rst => {
                    this.$emit("onSuccess");
                    this.hide();
                    this.$message.success("移动成功");
                }).catch(err => {
                    this.$message.error(err.message);
                }); 
            }
        }
    }
</script>
<style scoped>
    .tree {
        height: 300px;
        overflow: auto;
    }
</style>