<template>
    <div style="height:100%">
        <div class="page-header">产品类目属性字典</div>
        <div style="margin:20px;text-align:right;">
            <el-button @click="add()" size="small" type="primary">新增类目</el-button>
        </div>
        <div class="block" style="height: calc(100% - 159px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%" row-key="id" border
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column prop="name" label="类目">
                        <!-- <template slot-scope="scope">
                            <div>{{scope.row.name}}</div>
                        </template> -->
                    </el-table-column>
                    <el-table-column prop="id" label="ID">
                    </el-table-column>
                    <el-table-column prop="data.order" label="排序">
                        <template slot-scope="scope">
                            <div>{{scope.row.data.order}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="400">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="addSon(scope.row)">
                                + 添加子类目
                            </el-button>
                            <el-button type="text" size="small" @click="edit(scope.row)">
                                修改类目
                            </el-button>
                            <el-button type="text" size="small" @click="editAttr(scope.row)">
                                修改类目属性
                            </el-button>
                            <el-button type="text" size="small" @click="move(scope.row)">
                                类目移动
                            </el-button>
                            <el-button type="text" size="small" @click="del(scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.pageNumber" :page-size="queryOpt.pageCount" :total="total"></el-pagination>
            </div>
        </div>

        <el-dialog width="700px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditMove v-if="popTitle=='类目移动'" :cat="ruleForm" :popTitle="popTitle" @onSuccess="refreshItems" @hide="popVisible=false"></EditMove>
            <ClassAttr v-if="popTitle=='修改类目属性'" :catId="ruleForm.id" :popTitle="popTitle" @onSuccess="refreshItems" @hide="popVisible=false"></ClassAttr>
            <EditClass v-if="popTitle!='类目移动'&&popTitle!='修改类目属性'" :form="ruleForm" :popTitle="popTitle" @onSuccess="refreshItems" @hide="popVisible=false"></EditClass>
        </el-dialog>
    </div>
</template>
<script>
    import { fetchTree } from '@/util';
    import { catList,catDel,catGetId } from '@/service/dictionary';
    import EditClass from './EditClass.vue';
    import ClassAttr from './ClassAttr.vue';
    import EditMove from './EditMove.vue';
    export default {
        components: {
            EditClass, ClassAttr, EditMove
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                class:[],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                queryOpt:{
                    pageNumber:1,
                    pageCount:15,
                },
                total:0,
                list:[],
                ruleForm:"",
            }
        },
        mounted() {

        },
        created() {
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                console.log('refreshItems'); 
                catList().then(rst => {
                    if (rst && rst.length>0) {
                        // this.list = fetchTree(Object.values(rst), null);
                        this.list = fetchTree(rst, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
                    }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add() {
                this.popTitle = "新增类目";
                this.popVisible = true;
                this.ruleForm = {
                    name:"",
                    icon:"",
                    order:"",
                };
            },
            addSon(item) {
                this.popTitle = "新增子类目";
                this.popVisible = true;
                this.ruleForm = {
                    parentId:item.id,
                    parentName:item.name,
                    name:"",
                    icon:"",
                    order:"",
                };
                console.log("666",item);
            },
            edit(item) {
                this.popTitle = "修改类目";
                this.popVisible = true;
                this.ruleForm = {
                    id:item.id,
                    icon:item.icon,
                    parentId:item.parentId,
                    name:item.name,
                    order:item.order,
                };
                console.log("666",item);
            },
            editAttr(item) {
                this.popTitle = "修改类目属性";
                this.popVisible = true;
                this.ruleForm = {
                    id:item.id,
                };
            },
            move(item) {
                this.popTitle = "类目移动";
                this.popVisible = true;
                this.ruleForm = {
                    id:item.id,
                    name: item.name
                };
            },
            del(item) {
                console.log(item);
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    if (item.children) {
                        this.$message.error("请先删除此类目下的所有子类目");
                    } else {
                        catDel({id:item.id}).then(rst => {
                            this.refreshItems();
                            this.$message.success('删除成功!');
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
                    }
                });
            }
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .icon {
        font-size: 13px;
    }
</style>