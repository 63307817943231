import { render, staticRenderFns } from "./EditMove.vue?vue&type=template&id=6b6b4303&scoped=true&"
import script from "./EditMove.vue?vue&type=script&lang=js&"
export * from "./EditMove.vue?vue&type=script&lang=js&"
import style0 from "./EditMove.vue?vue&type=style&index=0&id=6b6b4303&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b6b4303",
  null
  
)

export default component.exports