<template>
    <div style="max-width: 400px;margin: 0 auto;">
        <el-form :model="ruleForm" :rules="rules" label-width="80px" ref="formCheck" class="login-form">
            <el-form-item prop="name" label="类目名称">
                <el-input placeholder="请输入类目名称" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item prop="icon" label="类目图标">
                <el-upload class="comp-cert-uploader" action="xx" :show-file-list="false" :accept="uploadAccept" :http-request="uploadLogo" :before-upload="beforeUpload">
                    <img v-if="ruleForm.icon" :src="imgUrl+ruleForm.icon" class="comp-cert-logo">
                    <i v-else class="el-icon-plus comp-cert-logo-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="上级类目">
                <div v-if="ruleForm.parentId">{{ruleForm.parentName}}</div>
                <div v-else>--</div>
            </el-form-item>
            <el-form-item prop="order" label="排序">
                <el-input type="number" placeholder="请输入数字" v-model="ruleForm.order"></el-input>
            </el-form-item>
            <!-- <div class="header flex">
                <div class="flex-1">字段名称</div>
                <div class="flex-1">选项个数</div>
                <div style="width:100px;text-align:center;">操作</div>
            </div> -->
            <!-- <div class="list">
                <div v-for="(item,idx) in ruleForm.attrs" :key="idx" class="flex">
                    <div class="flex-1">
                        <div class="flex-1">
                            <el-input style="width:120px;margin-right:5px;" size="mini" v-model="item.name" placeholder="请输入内容"></el-input>
                        </div>
                    </div>
                    <div class="flex-1">
                        <div v-if="item.items && item.items.length>0" class="flex-1">{{item.items.length}}</div>
                        <div v-else class="flex-1">0</div>
                    </div>
                    <div style="width:100px;text-align:center;">
                        <el-button @click="edit(idx)" type="text" size="mini">查看/修改</el-button>
                        <el-button @click="delAttribute(idx)" type="text" size="mini">删除</el-button>
                    </div>
                </div>
                <div class="add flex" style="padding:10px 0;">
                    <div><el-input style="width:120px;margin-right:5px;" size="mini" v-model="createdName" placeholder="请输入内容"></el-input></div>
                    <div><el-button size="mini" @click="addAttribute()" type="primary">添加</el-button></div>
                </div>
            </div> -->
            <div style="padding-top:20px;" class="flex flex-justify-around">
                <el-button type="primary" size="small" @click="hide()">取消</el-button>
                <el-button type="primary" size="small" @click="submitForm()">确定</el-button>
            </div>
        </el-form>

    </div>
</template>
<script>
    import { IMG_URL_PRE } from '@/config';
    import { uploadFile,delFile } from '@/service/common';
    import { catAdd, catEdit, catGetId } from '@/service/dictionary';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                uploadAccept: 'image/jpeg,image/gif,image/png',
                imgUrl: IMG_URL_PRE,
                popVisible: false,
                pop_title: '',
                rules: {
                    name: [{ required: true, message: '请输入类目名称', trigger: 'blur' },],
                    order: [{ required: true, message: '请输入排序数字', trigger: 'blur' },],
                },
                ruleForm:{
                    name:"",
                    icon:"",
                    order:"",
                },
                saveImg:null,
            }
        },
        watch:{
            form:{
                handler() {
                    if (this.popTitle == "修改类目") {
                        this.refreshItems();
                    } else {
                        this.ruleForm = this.form;
                    }
                },
                deep: true
            }
        },
        created() {
            if (this.popTitle == "修改类目") {
                this.refreshItems();
            } else {
                this.ruleForm = this.form;
            }
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                catGetId(this.form.id).then(rst => {
                    if (rst) {
                        this.ruleForm = {
                            id:rst.id,
                            parentId:rst.parentId,
                            name:rst.name,
                            icon:rst.icon,
                            order:rst.order,
                        }
                        this.saveImg = this.ruleForm.icon;
                    }
                    if (rst && rst.parent) {
                        this.ruleForm.parentName = rst.parent.name;
                    }
                    console.log(rst);
                    console.log(this.ruleForm);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            uploadLogo(item){
                uploadFile(item.file,'admin/cat/icon', {
                    onUploadProgress: event => {
                    item.file.percent = (event.loaded / event.total) * 100;
                    item.onProgress(item.file);
                    }
                }).then(url=>{
                    this.ruleForm.icon = url;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            beforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error('上传LOGO图片大小不能超过 10MB!');
                }
                return isLt2M;
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                // console.log(JSON.stringify(this.ruleForm));
                if (this.ruleForm.id) {
                    catEdit(this.ruleForm).then(rst => {
                        this.$emit("onSuccess");
                        this.hide();
                        this.$message.success("修改成功");
                        // if (this.saveImg) delFile(this.saveImg).then(() => {});
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else {
                    catAdd(this.ruleForm).then(rst => {
                        this.$emit("onSuccess");
                        this.hide();
                        this.$message.success("添加成功");
                        // if (this.saveImg) delFile(this.saveImg).then(() => {});
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
            hide() {
                this.$emit("hide");
            },
        }
    }
</script>
<style scoped>
    .header>div {
        font-weight: 600;
        color: #000;
        line-height:30px;
    }
    .list>div {
        margin: 10px 0;
    }
    .comp-cert-uploader {
        width: 70px;
        height: 70px;
    }
    .comp-cert-uploader>>> .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 70px;
        height: 70px;
        border:1px dashed #ccc;
    }

    .comp-cert-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .comp-cert-logo-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 50px;
        height: 50px;
        line-height: 70px;
        text-align: center;
    }

    .comp-cert-logo {
        width: 70px;
        height: 70px;
        display: block;
    }
</style>