<template>
    <div style="max-width: 400px;margin: 0 auto;">
        <el-form :model="ruleForm" :rules="rules" label-width="100px" ref="formCheck" class="login-form">
            <el-form-item prop="name" label="类目属性名">
                <el-input placeholder="请输入类目属性名" v-model="ruleForm.name" style="width:220px" size="small"></el-input>
            </el-form-item>
            <el-form-item v-if="popTitle=='新增SPU属性' || popTitle=='修改SPU属性'" prop="nullable" label="是否必填">
                <el-switch v-model="ruleForm.nullable" @change="handleSwitch" 
                    active-text="必填" inactive-text="非必填"></el-switch>
            </el-form-item>
            <el-form-item v-if="popTitle=='新增SPU属性' || popTitle=='修改SPU属性'" prop="type" label="选项类型">
                <el-select v-model="ruleForm.type" @change="handleSelect" placeholder="请选择" size="small">
                    <el-option v-for="item in options" :key="item.type" 
                    :label="item.name" :value="item.type"></el-option>
                </el-select>
            </el-form-item>
            <div style="padding-top:20px;" class="flex flex-justify-around">
                <el-button type="primary" size="small" @click="hide()">取消</el-button>
                <el-button type="primary" size="small" @click="submitForm()">确定</el-button>
            </div>
        </el-form>

    </div>
</template>
<script>
    import { IMG_URL_PRE } from '@/config';
    import { uploadFile,delFile } from '@/service/common';
    import { catAttrAdd, catAttrEdit, catSpuAttrAdd, catSpuAttrEdit, } from '@/service/dictionary';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                options:[
                    {id:1, name: "单选", type:'single'},
                    {id:2, name: "平铺多选", type:'mutil'}
                ],
                rules: {
                    name: [{ required: true, message: '请输入类目属性名', trigger: 'blur' },],
                    // nullable: [{ required: true, message: '请输入排序数字', trigger: 'blur' },],
                    type: [{ required: true, message: '请选择选项类型', trigger: 'blur' },],
                },
                ruleForm:{
                    name: null,
                    nullable: false,
                    type: null
                },
            }
        },
        watch:{
            popTitle:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            console.log(this.form);
            console.log(this.ruleForm);
        },
        mounted() {},
        methods: {
            handleSwitch(e) {
                console.log(e);
            },
            handleSelect(e) {
                console.log(e);
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                // console.log(JSON.stringify(this.ruleForm));
                if (this.popTitle == '新增SPU属性') {
                    catSpuAttrAdd(this.ruleForm).then(rst => {
                        this.$emit("onSuccess");
                        this.hide();
                        this.$message.success("新增SPU属性成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle == '修改SPU属性') {
                    catSpuAttrEdit(this.ruleForm).then(rst => {
                        this.$emit("onSuccess");
                        this.hide();
                        this.$message.success("修改SPU属性成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle == '新增SKU属性') {
                    catAttrAdd(this.ruleForm).then(rst => {
                        this.$emit("onSuccess");
                        this.hide();
                        this.$message.success("新增SKU属性成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle == '修改SKU属性') {
                    catAttrEdit(this.ruleForm).then(rst => {
                        this.$emit("onSuccess");
                        this.hide();
                        this.$message.success("修改SKU属性成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
            hide() {
                this.$emit("hide");
            },
        }
    }
</script>
<style scoped>
    .header>div {
        font-weight: 600;
        color: #000;
        line-height:30px;
    }
    .list>div {
        margin: 10px 0;
    }
    .comp-cert-uploader {
        width: 70px;
        height: 70px;
    }
    .comp-cert-uploader>>> .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 70px;
        height: 70px;
        border:1px dashed #ccc;
    }

    .comp-cert-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .comp-cert-logo-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 50px;
        height: 50px;
        line-height: 70px;
        text-align: center;
    }

    .comp-cert-logo {
        width: 70px;
        height: 70px;
        display: block;
    }
</style>