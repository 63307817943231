<template>
    <div class="editorAttr">
        <div class="header flex">
            <div class="flex-1">选项</div>
            <div style="width:100px;text-align:center;">操作</div>
        </div>
        <!-- <div class="content">
            <div v-if="list && list.length>0" class="list">
                <div v-for="(item,idx) in list" :key="idx" class="flex">
                    <div class="flex-1">
                        <el-input style="width:140px;margin-right:5px;" size="mini" v-model="item.value" placeholder="请输入内容"></el-input>
                    </div>
                    <div style="width:100px;text-align:center;">
                        <el-button @click="delClass(idx)" type="text" size="mini">删除</el-button>
                    </div>
                </div>
            </div>
        </div> -->
        <div v-if="list && list.length>0" class="content" ref="load">
            <draggable class="list-group" v-model="list"
                @change="changeSort"
                v-bind="dragOptions" tag="ul">
                <transition-group type="transition" name="flip-list">
                    <li v-for="(item,idx) in list" :key="idx" aria-hidden="true" class="list-group-item flex">
                        <div class="flex-1">
                            <el-input @change="editSubmit(item,idx)" :disabled="listId!=item.id" style="width:120px;margin-right:5px;" maxlength="8" size="mini" v-model="item.value" placeholder="请输入内容"></el-input>
                            <el-button v-if="listId==item.id" style="margin-left:10px;" @click="editSubmit(item,idx)" type="text" size="mini">确定</el-button>
                            <el-button v-if="listId!=item.id" style="margin-left:10px;" @click="editClass(item,idx)" type="text" size="mini">修改</el-button>
                        </div>
                        <div style="width:100px;text-align:center;">
                            <el-button @click="delClass(item,idx)" type="text" size="mini">删除</el-button>
                        </div>
                    </li>
                </transition-group>
            </draggable>
        </div>
        <div class="add flex" style="padding: 30px 0px 30px 30px;">
            <div><el-input style="width:140px;margin-right:5px;" size="mini" v-model="createdName" placeholder="请输入内容"></el-input></div>
            <div><el-button size="mini" @click="addClass()" type="primary">确认</el-button></div>
            <div class="font14-grey1" style="line-height:28px;margin-left:15px;color:red">可拖拽排序</div>
        </div>
        <div style="padding-top:20px;" class="flex flex-justify-around">
            <el-button type="primary" size="small" @click="hide()" style="width: 80px;">关闭</el-button>
        </div>
    </div>
</template>
<script>
    import draggable from 'vuedraggable';
    import { 
        catAttrValueList, catAttrValueAdd, catAttrValueEdit, catAttrValueDel, catAttrValueSort, 
        catSpuAttrValueList, catSpuAttrValueAdd, catSpuAttrValueEdit, catSpuAttrValueDel, catSpuAttrValueSort 
    } from '@/service/dictionary';

    export default {
        components:{
            draggable
        },
        model: {
            prop: 'items',
            event: 'change',
        },
        props: ['itemId','popTitle','itemType'],
        data() {
            return {
                list:[],
                listId:null,
                createdName:"",
            }
        },
        computed: {
            dragOptions() {
                return {
                    animation: 100,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        watch:{
            itemId:{
                handler() {
                    this.refreshItems();
                },
                deep: true
            },
            itemType:{
                handler() {
                    this.refreshItems();
                },
                deep: true
            }
        },
        created() {
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                const _loading = this.$loading({
                    lock: true,
                    target:this.$refs['load'],
                    text: '拼命加载中......',
                });
                if (this.itemType == 0) {
                    catSpuAttrValueList(this.itemId).then(rst => {
                        this.list=rst;
                        console.log(this.list);
                        _loading.close();
                    }).catch(err => {
                        _loading.close();
                        this.$message.error(err.message);
                    });
                } else {
                    catAttrValueList(this.itemId).then(rst => {
                        this.list=rst;
                        console.log(this.list);
                        _loading.close();
                    }).catch(err => {
                        _loading.close();
                        this.$message.error(err.message);
                    });
                }
            },
            addClass() {
                if (!this.createdName) {
                    return this.$message.error("请输入内容");
                }
                let length = 0;
                let obj = {
                    value:this.createdName,
                    catAttrId:this.itemId,
                    order:0
                }
                if (this.list && this.list.length>0) {
                    obj.order = this.list.length;
                }
                if (this.itemType == 0) {
                    catSpuAttrValueAdd(obj).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else {
                    catAttrValueAdd(obj).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
            changeSort(e) {
                let sortList = [];
                this.list.forEach((e,idx) => {
                    sortList.push({ id:e.id, order:idx })
                });
                if (this.itemType == 0) {
                    catSpuAttrValueSort(sortList).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else {
                    catAttrValueSort(sortList).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
            editClass(item,index) {
                this.listId = item.id;
            },
            editSubmit(item,index) {
                if (!item.value) {
                    return this.$message.error("请输入内容");
                }
                let obj = {
                    id:item.id,
                    value:item.value,
                    catAttrId:item.catAttrId
                }
                if (this.itemType == 0) {
                    catSpuAttrValueEdit(obj).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else {
                    catAttrValueEdit(obj).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
                this.listId = null;
            },
            delClass(item,index) {
                this.$confirm('确认删除?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    if (this.itemType == 0) {
                        catSpuAttrValueDel(item.id).then(rst => {
                            this.$message.success("删除成功");
                            this.refreshItems();
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
                    } else {
                        catAttrValueDel(item.id).then(rst => {
                            this.$message.success("删除成功");
                            this.refreshItems();
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
                    }
                });
            },
            hide() {
                this.$emit("hide");
            },
        }
    }
</script>
<style scoped>
    .header {
        padding:0 30px;
        border: 1px solid #EBEEF5;
    }
    .header>div {
        font-weight: 600;
        color: #000;
        line-height:38px;
    }
    .content {
        padding: 10px 0;
        border: 1px solid #EBEEF5;
        border-top: none;
    }
    .content .list-group {
        max-height: 320px;
        overflow-y: auto;
        padding: 0 20px;
    }
    .content .list-group .list-group-item {
        padding: 8px 10px;
        cursor: pointer;
        border-bottom: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
        border-radius: 5px;
    }
    .content .list-group .list-group-item:first-child {
        border-top: 1px solid #EBEEF5;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb7a;
    }
</style>