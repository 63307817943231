<template>
    <div style="max-width: 600px;margin: 0 auto;">
        <div class="tab_menu flex flex-justify-around">
            <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="menu_item" :class="{'cur':tabIndex == index}">
                <span>{{item.name}}</span>
            </div>
        </div>
        <div v-if="tabIndex==0" class="block-body">
            <div class="header flex">
                <div class="flex-1">字段名称</div>
                <div style="width:80px;text-align:center;">选项个数</div>
                <div style="width:100px;text-align:center;">是否必填</div>
                <div style="width:100px;text-align:center;">选项类型</div>
                <div style="width:120px;text-align:center;">操作</div>
            </div>
            <div class="list">
                <div v-for="(item,idx) in attrs" :key="idx" class="flex">
                    <div class="flex-1">
                        <el-input @change="editSubmit(item,tabIndex)" :disabled="listId!=item.id" style="width:120px;margin-right:5px;" size="mini" v-model="item.name" placeholder="请输入内容"></el-input>
                        <el-button v-if="listId==item.id" style="margin-left:10px;" @click="editSubmit(item,tabIndex)" type="text" size="mini">确定</el-button>
                        <el-button v-if="listId!=item.id" style="margin-left:10px;" @click="editAttr(item,idx)" type="text" size="mini">修改</el-button>
                    </div>
                    <div style="width:80px;text-align:center;">
                        <div class="flex-1">{{item.count || 0}}</div>
                    </div>
                    <div style="width:100px;text-align:center;">
                        <div v-if="item.nullable">必填</div>
                        <div v-else>非必填</div>
                    </div>
                    <div style="width:100px;text-align:center;">
                        <div v-if="item.type=='single'">单选</div>
                        <div v-if="item.type=='mutil'">平铺多选</div>
                    </div>
                    <div style="width:120px;text-align:center;">
                        <el-button @click="edit(item,tabIndex)" type="text" size="mini">修改</el-button>
                        <el-button @click="view(item,tabIndex)" type="text" size="mini">查看</el-button>
                        <el-button @click="delAttr(item,tabIndex)" type="text" size="mini">删除</el-button>
                    </div>
                </div>
                <div class="add flex" style="padding:30px 0;">
                    <!-- <div><el-input style="width:120px;margin-right:5px;" size="mini" v-model="createdName" placeholder="请输入内容"></el-input></div> -->
                    <div><el-button size="mini" @click="addAttr(tabIndex)" type="primary">添加</el-button></div>
                </div>
            </div>
        </div>
        <div v-if="tabIndex==1" class="block-body">
            <div class="header flex">
                <div class="flex-1">字段名称</div>
                <div class="flex-1" style="text-align:center;">选项个数</div>
                <div style="width:120px;text-align:center;">操作</div>
            </div>
            <div class="list">
                <div v-for="(item,idx) in attrs" :key="idx" class="flex">
                    <div class="flex-1">
                        <el-input @change="editSubmit(item,tabIndex)" :disabled="listId!=item.id" style="width:120px;margin-right:5px;" size="mini" v-model="item.name" placeholder="请输入内容"></el-input>
                        <el-button v-if="listId==item.id" style="margin-left:10px;" @click="editSubmit(item,tabIndex)" type="text" size="mini">确定</el-button>
                        <el-button v-if="listId!=item.id" style="margin-left:10px;" @click="editAttr(item,idx)" type="text" size="mini">修改</el-button>
                    </div>
                    <div class="flex-1" style="text-align:center;">
                        <div class="flex-1">{{item.count || 0}}</div>
                    </div>
                    <div style="width:120px;text-align:center;">
                        <el-button @click="edit(item,tabIndex)" type="text" size="mini">修改</el-button>
                        <el-button @click="view(item,tabIndex)" type="text" size="mini">查看</el-button>
                        <el-button @click="delAttr(item,tabIndex)" type="text" size="mini">删除</el-button>
                    </div>
                </div>
                <div class="add flex" style="padding:30px 0;">
                    <!-- <div><el-input style="width:120px;margin-right:5px;" size="mini" v-model="createdName" placeholder="请输入内容"></el-input></div> -->
                    <div><el-button size="mini" @click="addAttr(tabIndex)" type="primary">添加</el-button></div>
                </div>
            </div>
        </div>
        <div style="padding-top:20px;" class="flex flex-justify-around">
            <el-button style="width:80px" type="primary" size="small" @click="hide()">关闭</el-button>
        </div>

        <el-dialog width="700px" :title="pop_title" :visible.sync="popVisible" :append-to-body="true" :modal-append-to-body="false">
            <EditClassAttr v-if="pop_title=='修改SPU属性' || pop_title=='修改SKU属性' || pop_title=='新增SPU属性' || pop_title=='新增SKU属性'" 
                :form="ruleFrom" :popTitle="pop_title" @hide="onSuccess"></EditClassAttr>
            <EditClassSon v-else :itemId="itemId" :itemType="itemType" :popTitle="pop_title" @hide="onSuccess"></EditClassSon>
        </el-dialog>

    </div>
</template>
<script>
    import { IMG_URL_PRE } from '@/config';
    import { uploadFile,delFile } from '@/service/common';
    import { 
        catAttrList, catAttrAdd, catAttrEdit, catAttrDel,
        catSpuAttrList, catSpuAttrAdd, catSpuAttrEdit, catSpuAttrDel,
    } from '@/service/dictionary';
    import EditClassAttr from './EditClassAttr.vue';
    import EditClassSon from './EditClassSon.vue';
    export default {
        components: {
            EditClassAttr, EditClassSon,
        },
        props: ['catId','popTitle'],
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                popVisible: false,
                pop_title: '',
                tabList:[
                    {name:"SPU属性"},
                    {name:"SKU属性"},
                ],
                tabIndex:0,
                attrs:[],
                listId:null,
                itemId:null,
                itemType: null, // 0、SPU  1、SKU
                createdName:"",
                ruleFrom:{},
            }
        },
        watch:{
            catId:{
                handler() {
                    this.refreshItems();
                },
                deep: true
            }
        },
        created() {
            this.refreshItems();
        },
        mounted() {},
        methods: {
            // 选项卡
            tab(index) {
                this.tabIndex = index;
                this.refreshItems();
            },
            refreshItems() {
                console.log('refreshItems');
                if (this.tabIndex == 0) {
                    catSpuAttrList(this.catId).then(rst => {
                        this.attrs = rst;
                        console.log(this.attrs);
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                } else {
                    catAttrList(this.catId).then(rst => {
                        this.attrs = rst;
                        console.log(this.attrs);
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                }
            },
            addAttr(type) {
                // if (!this.createdName) {
                //     return this.$message.error("请输入内容");
                // }
                // catAttrAdd({name:this.createdName,catId:this.catId}).then(rst => {
                //     this.refreshItems();
                // }).catch(err => {
                //     this.$message.error(err.message);
                // });
                this.popVisible = true;
                if (type==0) {
                    this.pop_title = "新增SPU属性";
                    this.ruleFrom = {
                        name: null,
                        catId: this.catId,
                        nullable: null,
                        type: null
                    }
                } else if (type==1) {
                    this.pop_title = "新增SKU属性";
                    this.ruleFrom = {
                        catId: this.catId,
                        name: null,
                    }
                }
            },
            editAttr(item) {
                this.listId = item.id;
            },
            editSubmit(item,type) {
                if (!item.name) {
                    return this.$message.error("请输入内容");
                }
                let obj = {
                    id:item.id, name:item.name, catId:item.catId
                }
                if (type==0) {
                    catSpuAttrEdit(obj).then(rst => {
                        this.$message.success("修改成功");
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (type==1) {
                    catAttrEdit(obj).then(rst => {
                        this.$message.success("修改成功");
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
                this.listId = null;
            },
            delAttr(item,type) {
                this.$confirm('确认删除?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    if (type==0) {
                        catSpuAttrDel(item.id).then(rst => {
                            this.$message.success("删除成功");
                            this.refreshItems();
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
                    } else if (type==1) {
                        catAttrDel(item.id).then(rst => {
                            this.$message.success("删除成功");
                            this.refreshItems();
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
                    }
                });
            },
            hide() {
                this.$emit("hide");
            },
            onSuccess() {
                this.popVisible = false;
                this.refreshItems();
            },
            view(item,type) {
                this.pop_title = item.name;
                this.itemId = item.id;
                this.itemType = type;
                this.popVisible = true;
            },
            edit(item,type) {
                this.popVisible = true;
                if (type==0) {
                    this.pop_title = "修改SPU属性";
                    this.ruleFrom = {
                        id: item.id,
                        name: item.name,
                        catId: item.catId,
                        nullable: item.nullable,
                        type: item.type
                    }
                } else if (type==1) {
                    this.pop_title = "修改SKU属性";
                    this.ruleFrom = {
                        id: item.id,
                        name: item.name
                    }
                }
            },
        }
    }
</script>
<style scoped>
    .tab_menu {
        width: 100%;
        padding: 20px 0;
    }
    .tab_menu .menu_item {
        width: 80px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        font-size: 14px;
        color: #5074ee;
        border: 1px solid #5074ee;
    }
    .tab_menu .menu_item.cur {
        color: #ffffff;
        background: #5074ee;
    }

    .header>div {
        font-weight: 600;
        color: #000;
        line-height:30px;
    }
    .list>div {
        margin: 10px 0;
    }
    .comp-cert-uploader {
        width: 70px;
        height: 70px;
    }
    .comp-cert-uploader>>> .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 70px;
        height: 70px;
        border:1px dashed #ccc;
    }

    .comp-cert-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .comp-cert-logo-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 50px;
        height: 50px;
        line-height: 70px;
        text-align: center;
    }

    .comp-cert-logo {
        width: 70px;
        height: 70px;
        display: block;
    }
</style>